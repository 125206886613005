@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200;300;600;700;800&display=swap');

@import "primereact/resources/themes/lara-light-indigo/theme.css";     
@import "primereact/resources/primereact.min.css";                                       
        
@font-face {
  font-family: 'altone_b';
  src: local('altone-bold'), url('./assets/fonts/Altone-Bold.ttf') format('woff');
}

@font-face {
  font-family: 'altone_sb';
  src: local('altone-semibold'), url('./assets/fonts/Altone-SemiBold.ttf') format('woff');
}

@font-face {
  font-family: 'altone_m';
  src: local('altone-medium'), url('./assets/fonts/Altone-Medium.ttf') format('woff');
}

@font-face {
  font-family: 'altone_r';
  src: local('altone-regular'), url('./assets/fonts/Altone-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'dmsans_r';
  src: local('DMSans'), url('./assets/fonts/DMSans-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'dmsans_m';
  src: local('DMSans-medium'), url('./assets/fonts/DMSans-Medium.ttf') format('woff');
}

@font-face {
  font-family: 'dmsans_b';
  src: local('DMSans-bold'), url('./assets/fonts/DMSans-Bold.ttf') format('woff');
}

@font-face {
  font-family: 'inter';
  src: local('Inter'), url(./assets/fonts/Inter-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'inter_thin';
  src: local('Inter-Thin'), url(./assets/fonts/Inter-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'inter_extraLight';
  src: local('Inter-ExtraLight'), url(./assets/fonts/Inter-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'inter_light';
  src: local('Inter-Light'), url(./assets/fonts/Inter-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'inter_regular';
  src: local('Inter-Regular'), url(./assets/fonts/Inter-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'inter_medium';
  src: local('Inter-Medium'), url(./assets/fonts/Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'inter_semibold';
  src: local('Inter-Medium'), url(./assets/fonts/Inter-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'inter_bold';
  src: local('Inter-Medium'), url(./assets/fonts/Inter-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'inter_extraBold';
  src: local('Inter-ExtraBold'), url(./assets/fonts/Inter-ExtraBold.ttf) format('truetype');
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
  background-color: #f7f9fb;
}

#nprogress .bar {
  background: #6463fd;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #ffffff, 0 0 5px #ffffff;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.overlay {
  width: 100%;
  height: 100%;
  background: #0D0F11;
  opacity: 50%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  /* filter: blur(20px); */
}
.modal {
  position: absolute;
  z-index: 20;

  /* Center the modal */
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  -webkit-animation: modal_animation 300ms linear;
	animation: modal_animation 300ms linear;
   opacity: 1;
	transition: 1s;
}
@keyframes modal_animation {
	0% {
		opacity: 0;
		transform: translateX(-50%) translateY(-50%) scale(.8);
	}
	100% {
		opacity: 1;
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  border-radius: 50px;
}

.switch_active {
  background-color: #7F56D9;
}
.switch_inactive {
  background-color: #ccc;
}

.switchball {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
.active_switchball {
  right: 6px;
}
.inactive_switchball {
  left: 6px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 50px;
}

.slider.round:before {
  border-radius: 60%;
}
.transition_left {
	opacity: 1;
	transform: translateX(0px);
	transition: 1s;

	-webkit-animation: left_animation 300ms linear;
	animation: left_animation 300ms linear;
}

@keyframes left_animation {
	0% {
		opacity: 0;
		transform: translateX(20px);
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

.transition_right {
	opacity: 1;
	transform: translateX(0px);
	transition: 1s;

	-webkit-animation: right_animation 300ms linear;
	animation: right_animation 300ms linear;
}

@keyframes right_animation {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}
	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

@keyframes success_animation {
	0% {
		opacity: 0;
		transform: scale(.5);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.screen-height {
  max-height: calc(100vh - 68px);
}
.sidebar__wrapper {
  transition: 0.3s all ease-out;
  z-index: 5;
}
input:checked + div {
  @apply border-purple;
}
input:checked + div svg {
  @apply block;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:disabled, input:disabled:hover {
  cursor: not-allowed;
}
input[type="month"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type="month"]::before{
  content: attr(placeholder) !important;
  color: #aaa;
  position: absolute;
  /* width: 100%; */
}

input[type="month"]:focus::before,
input[type="month"]:active::before {
  content: "";
  /* width: 0%; */
}

input[type="month"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(assets/images/Calendar.png) no-repeat;
  /* width: 30px; */
  /* height: 30px; */
  border-width: thin;
}

.tabs__item {
  position: relative;
  display: inline-block;
  /* text-transform: Capitalize; */
  padding: 15px 0;
  /* width: calc(100% / 15); */
  /* text-align: center; */
  font-size: 14px;
}
.tabs__item:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 0;
  background: #5034C4;
  transition: 0.3s;
}
.tabs__item.active:after {
  width: 100%;
}
.tabs__item:hover {
  cursor: pointer;
}
.table-active {
  display: block;
}
.table-active:after {
  width: 100%;
}
.product__img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #F9FAFB;
  /* margin: 0 auto; */
}
.product__img img {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.profile_picture {
  object-position: center;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.spinner {
	display: block;
}

.spinner-icon {
	width: 18px;
	height: 18px;
	box-sizing: border-box;

	border: solid 2px #5034C4;
	border-top-color: #ffffff;
	border-left-color: #ffffff;
	border-radius: 50%;

	-webkit-animation: spinner 400ms linear infinite;
	animation: spinner 400ms linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.scroll-styled::-webkit-scrollbar {
    width: 10px;
    height: 0px;
  }
  .scroll-styled::-webkit-scrollbar-track {
    width: 3px;
    border: 7px solid #ffffff;
    border-radius: 16px;
    box-shadow: inset 0 0 2.5px 2px rgba(0, 0, 0, 0.5);
  }
  .scroll-styled::-webkit-scrollbar-thumb {
    background: #C4C4C4;
    border-radius: 4px;
  }
  .overflow-wrap {
    overflow-wrap: break-word
  }
